<template>
	<section id="teachingstaff" class="teaching">
			<NumberPage label='04.' />
			<div class="teaching__images">
				<img class="teaching__images__1" src="../assets/images/greece.png" alt="greece" />
				<img class="teaching__images__2" src="../assets/images/greece-empty.png" alt="greece" />
				<img class="teaching__images__3" src="../assets/images/greece-empty.png" alt="greece" />
			</div>
			<div class="teaching__text">
				<span class="teaching__text__letter">{{ title[0] }}</span>
				<span class="teaching__text__title">{{title}}</span>
				<div class="teaching__text__description" v-html="content"></div>
			</div>
	</section>
</template>

<script>
import NumberPage from '@/components/NumberPage.vue'

export default {
	name: 'TeachingStaff',
	props: ['data'],
	components: {
		NumberPage
	},
	computed: {
		title() {
      return this.data.title
    },
    content() {
      return this.data.description
		}
	}	
}
</script>

<style lang='scss' scoped>
.teaching {
	@include container;
	padding-top: verticalSpace(green, mobile);
	padding-bottom: 60px;

	@include mq($from: tablet) {
		padding-top: 0;
		padding-bottom: 70px;
	}

	@include mq($from: desktop) {
		padding-bottom: 180px;
	}

	& .number_page {
		color: $white;

		@include mq($from: tablet) {
			position: absolute;
			top: 55vw;
			left: returnColumnOffset(1, tablet);
			margin-left: 0;
			z-index: 12;
		}

		@include mq($from: desktop) {
			top: 34vw;
			left: returnColumnOffset(4, desktop)
		}
	}

	&__images {
		position: relative;
		margin-top: verticalSpace(wood, mobile);

		@include mq($from: tablet) {
			z-index: 10;
			margin: 0 returnColumnOffset(2, tablet); 
		}

		@include mq($from: desktop) {
			margin: 0 returnColumnOffset(6.5, desktop); 
		}

		img {
			display: block;
			width: 100%;
		}

		&__2,
		&__3 {
			position: absolute;
			top: 0;
			left: 0;
		}
	}

	&__text {
		position: relative;
		z-index: 11;
		text-align: center;
		color: $white;
		margin-top: -90px;

		@include mq($from: tablet) {
			margin-top: -140px;
			padding: 0 returnColumnOffset(4, tablet);
		}

		@include mq($from: desktop) {
			margin-top: -180px;
			padding: 0 returnColumnOffset(8, desktop);
		}

		&__letter {
			@include letter;
			display: block;
		}

		&__title {
			@include title;
		}
	}
}
</style>