<template>
  <svg 
   xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    role="presentation"
    viewBox="0 0 52 60"
  >
		<polygon :fill="secondaryColor" points="25.9949 0 0 15.000196 0 44.9974521 25.9949 60 52 44.9974521 52 15.000196"></polygon>
		<path :fill="iconColor" d="M18,38.3004855 L18,21.6995145 C18,21.1472297 18.4477153,20.6995145 19,20.6995145 C19.1699442,20.6995145 19.3370849,20.742825 19.4856429,20.8253572 L34.4265169,29.1258427 C34.9093006,29.3940559 35.0832444,30.0028592 34.8150312,30.4856429 C34.7243423,30.648883 34.589757,30.7834684 34.4265169,30.8741573 L19.4856429,39.1746428 C19.0028592,39.442856 18.3940559,39.2689122 18.1258427,38.7861285 C18.0433105,38.6375705 18,38.4704298 18,38.3004855 Z"></path>
  </svg>
</template>	

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: 'Akademeia Tutorial College Logo'
    },
    width: {
      type: [Number, String],
      default: 52
    },
    height: {
      type: [Number, String],
      default: 60
    },
    iconColor: {
      type: String,
      default: '#A38D5E'
    },
    secondaryColor: {
      type: String,
      default: '#FFFFFF'
    }
  }
}
</script>