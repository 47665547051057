<template>
<section id="contact">
  <div class="contact">
		<NumberPage label='06.' />
		<div id="map"></div>
		<div class="contact__container">
				<span class="contact__container__letter">{{ title[0] }}</span>
				<h2 class="contact__container__title">{{ title }}</h2>
				<div class="contact__container__address" v-html="content"></div>
			</div>
  </div>
	<div class="contact__background"></div>
</section>
</template>

<script>
import GoogleMapsLoader from 'google-maps'
import NumberPage from '@/components/NumberPage.vue'

export default {
	name: 'TheContact',
	props: ['data'],
	components: {
		NumberPage
	},
  data() {
    return {
			map: {
				style: [
						{
							"featureType": "all",
							"elementType": "geometry",
							"stylers": [
									{
											"color": "#202c3e"
									}
							]
						},
						{
								"featureType": "all",
								"elementType": "labels.text.fill",
								"stylers": [
										{
												"gamma": 0.01
										},
										{
												"lightness": 20
										},
										{
												"weight": "1.39"
										},
										{
												"color": "#ffffff"
										}
								]
						},
						{
								"featureType": "all",
								"elementType": "labels.text.stroke",
								"stylers": [
										{
												"weight": "0.96"
										},
										{
												"saturation": "9"
										},
										{
												"visibility": "on"
										},
										{
												"color": "#000000"
										}
								]
						},
						{
								"featureType": "all",
								"elementType": "labels.icon",
								"stylers": [
										{
												"visibility": "off"
										}
								]
						},
						{
								"featureType": "landscape",
								"elementType": "geometry",
								"stylers": [
										{
												"lightness": 30
										},
										{
												"saturation": "9"
										},
										{
												"color": "#29446b"
										}
								]
						},
						{
								"featureType": "poi",
								"elementType": "geometry",
								"stylers": [
										{
												"saturation": 20
										}
								]
						},
						{
								"featureType": "poi.park",
								"elementType": "geometry",
								"stylers": [
										{
												"lightness": 20
										},
										{
												"saturation": -20
										}
								]
						},
						{
								"featureType": "road",
								"elementType": "geometry",
								"stylers": [
										{
												"lightness": 10
										},
										{
												"saturation": -30
										}
								]
						},
						{
								"featureType": "road",
								"elementType": "geometry.fill",
								"stylers": [
										{
												"color": "#193a55"
										}
								]
						},
						{
								"featureType": "road",
								"elementType": "geometry.stroke",
								"stylers": [
										{
												"saturation": 25
										},
										{
												"lightness": 25
										},
										{
												"weight": "0.01"
										}
								]
						},
						{
								"featureType": "water",
								"elementType": "all",
								"stylers": [
										{
												"lightness": -20
										}
								]
						}
				],
				zoom: 15,
			}
    }
	},
	computed: {
		title() {
      return this.data.title
    },
    content() {
      return this.data.address
		},
		lat() {
			return parseFloat(this.data.position_on_map.lat)
		},
		lng() {
			return parseFloat(this.data.position_on_map.lng)
		},
		icon() {
			return this.data.pin.url
		}
	},
	methods: {
		initMap() {
			GoogleMapsLoader.KEY = this.data.google_map_api
			GoogleMapsLoader.load(google => {
				var map = new google.maps.Map(document.getElementById('map'), {
					center: {lat: this.lat, lng: this.lng},
					zoom: this.map.zoom,
					disableDefaultUI: true,
					styles: this.map.style
				})


				var iconBase = {
					url: this.icon,
					scaledSize: new google.maps.Size(25, 43)
				};

				new google.maps.Marker({
					position: {lat: this.lat, lng: this.lng},
						icon: iconBase,
						map: map
				});
			})
		}
	},
	mounted() {
		this.initMap();
	}
}
</script>

<style lang="scss" scoped>

section {
	position: relative;
}

.contact {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100vh;
	@include container;

	@include mq($from: tablet) {
		justify-content: center;
	}

	@include mq($from: desktop) {
			height: 100%;
			min-height: 100vh;
	}

	& #map {
		position: absolute !important;
		top:0;
		bottom:0;
		width: 100vw;
    align-self: center;
		z-index: -1;
	}

	& .number_page {
		color: $white;
	}

	&__container {
    align-self: center;
		background-image: linear-gradient(0deg, $gradientContact 56%, $gradientContact 64%, $gradientContactBlur 100%);
		width: 100vw;
		padding-bottom: 60px;
		text-align: center;
		color: $white;

		@include mq($from: tablet) {
			width: auto;
			align-self: auto;
			background-image: none;
			text-align: left;
			padding-top: verticalSpace(sand, tablet);
			padding-left: returnColumnOffset(1, tablet)
		}

		@include mq($from: desktop) {
			padding-top: 0;
			padding-bottom: 0;
			padding-left: returnColumnOffset(4, desktop);
		}

		&__letter {
			display: block;
			@include letter;
			@include mq($from: desktop) {
				display: none;
			}
		}

		&__title {
			@include title;
			&::first-letter {
					display: none;
					@include mq($from: desktop) {
						display: inline-block;
						font-size: 40rem;
						line-height: 1;
					}
			}

			@include mq($from: desktop) {
				padding-top: 30px;
			}
		}

		&__address {
			margin-top: verticalSpace(wood, mobile);
			white-space: nowrap;
			@include description;

			@include mq($from: tablet) {
				margin-top: verticalSpace(wood, tablet);
			}

			@include mq($from: desktop) {
				//wood
				margin-top: 0;
			}
		}
	}

	&__background {
		display: none;

		@include mq($from: tablet) {
			display: block;
			position: absolute;
			top: 0;
			bottom: 0;
			left: -10%;
			right: 50%;
			z-index: -1;
			background-color: $gradientContact;
		}
	}
}
</style>