<template>
	<div class="number_page" ref="number">
		<p>{{ label }}</p>
		<span></span>
	</div>
</template>

<script>
import ScrollMagic from 'scrollmagic';
// import { gsap } from "gsap";
// import "imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap";


export default {
	name: 'NumberComponent',
	props: {
		label: {
			type: String
		}
	},
	mounted() {	
		const controller = new ScrollMagic.Controller();
		// var tween = gsap.timeline()
		// tween.set(this.$refs.number, {opacity: 1}).to(this.$refs.number, {x:-100})

		new ScrollMagic.Scene({
			triggerElement: this.$refs.number,
			triggerHook: 1,
			offset: 100
			})
			.setClassToggle(this.$refs.number, "visible")
			// .setTween(tween)
			.addTo(controller);
		}
}
</script>

<style lang='scss' scoped>
.number_page {
	position: relative;
	width: 115px;
	max-width: 115px;
	z-index: 1;
	overflow: hidden;
	@include fontMarkWebProMedium;

	@include mq($from: tablet) {
		margin-left: returnColumnOffset(1, tablet);
	}

	@include mq($from: desktop) {
		margin-left: returnColumnOffset(4, desktop);
	}

	&	p {
		font-size: 1.2rem;
		font-weight: 500;
		line-height: calc(24/12);
		opacity: 0;

		transform: translateY(100%);
		transition: transform .3s, opacity .3s;
		transition-delay: .1s;
	}

	& span {
		position: absolute;
		bottom: 0;
		right: 0;
		width: 100%;
		height: 1px;
		background-color: $menuSpanColor;

		transform: translateX(-100%);
		transition: transform .4s;
	}
}

.visible {
	& p {
		opacity: 1;
		transform: translateY(0);
	}

	& span {
		transform: translateX(0);
	}
}
</style>