<template>
    <section class="management">
        <h2 class="management__title">Management</h2>
        <div class="management__container">
            <div
                v-for="(person, n) in persons"
                class="management__container__person"
                :key="`person_${n}`"
            >
                <div
                    class="management__container__person__background"
                    v-if="n === 0"
                >
                    <img :src="person.image.url" :alt="person.full_name" />
                    <span class="pattern"></span>
                </div>
                <img v-else :src="person.image.url" :alt="person.full_name" />
                <span></span>
                <div class="management__container__person__text">
                    <h4 class="management__container__person__text__title">
                        {{ person.full_name }}
                    </h4>
                    <p>{{ person.function }}</p>
                    <p>{{ person.education }}</p>
                    <span></span>
                    <div
                        class="management__container__person__text__description"
                        v-html="person.description"
                    ></div>
                </div>
            </div>
        </div>
        <div class="management__background"></div>
    </section>
</template>

<script>
export default {
    name: 'TheManagement',
    props: ['data'],
    data() {
        return {};
    },
    computed: {
        persons() {
            return this.data.map((data) => data.management_group);
        },
    },
};
</script>

<style lang="scss" scoped>
.management {
    display: flex;
    flex-direction: column;
    width: 100%;
    background: #e4d9c3;
    padding-top: 240px;
    padding-left: 10vw;
    padding-right: 10vw;

    @include mq($from: desktop) {
        justify-content: center;
    }

    &__title {
        font-size: 3.6rem;
        line-height: calc(45 / 36);
        @include fontTTFirstNeueDemiBold;
        padding-bottom: verticalSpace(pink, mobile);
        border-bottom: solid 1px $managementUnderline;
        color: #10254e;

        @include mq($from: desktop) {
            margin-left: returnColumnOffset(4, desktop);
            width: returnColumnOffset(16, desktop);
        }
    }

    &__container {
        padding-top: verticalSpace(lilac, mobile);
        padding-bottom: 60px;
        color: $white;

        @include mq($from: tablet) {
            display: flex;
            padding-top: verticalSpace(green, mobile);
            padding-bottom: 70px;
        }

        @include mq($from: desktop) {
            padding: verticalSpace(green, desktop)
                returnColumnOffset(4, desktop) 155px
                returnColumnOffset(4, desktop);
            justify-content: space-between;
        }

        &__person {
            @include mq($from: tablet) {
                flex: 0 0 50%;
            }

            @include mq($from: desktop) {
                flex: 0 0 43.5%;
            }

            &:nth-child(1) {
                & .management__container__person__text > p:nth-of-type(1) {
                    padding-right: 20%;

                    @include mq($from: desktop) {
                        padding-right: 0;
                    }
                }

                & .management__container__person__text__description {
                    margin-right: returnColumnOffset(2, tablet);
                }
            }

            &:nth-child(2) {
                padding-top: verticalSpace(ocean, mobile);

                & .management__container__person__text > p:nth-of-type(2) {
                    padding-right: 30%;

                    @include mq($from: desktop) {
                        padding-right: 0;
                    }
                }

                @include mq($from: tablet) {
                    padding-top: 0;

                    & .management__container__person__text {
                        padding-left: returnColumnOffset(2, tablet);
                    }
                }
            }

            &__background {
                @include mq($from: tablet) {
                    display: block;
                    position: relative;
                }

                & .pattern {
                    display: none;
                    @include mq($from: tablet) {
                        display: block;
                        position: absolute;
                        bottom: 0;
                        width: 94vw;
                        left: -3vw;
                        height: calc(100% + 205px);
                        z-index: -1;
                        background-image: url('../assets/images/management_bgperson.png');
                    }

                    @include mq($from: desktop) {
                        height: calc(100% + 296px);
                        width: 68.75vw;
                        max-width: 1466px;
                        min-width: 834px;
                        left: -42.85714%;
                    }
                }
            }

            & img {
                display: block;
                width: 68%;

                @include mq($from: tablet) {
                    width: 84%;
                    margin-bottom: verticalSpace(wood, tablet);
                }

                @include mq($from: desktop) {
                    width: 100%;
                    margin-bottom: verticalSpace(wood, desktop);
                }
            }

            & > span {
                &:nth-of-type(1) {
                    display: block;
                    width: 100%;
                    height: 1px;
                    background-color: $managementImageUnderline;
                    margin-bottom: verticalSpace(gold, mobile);

                    @include mq($from: tablet) {
                        display: none;
                    }
                }

                &:nth-of-type(2) {
                    display: block;
                    width: 65px;
                    height: 1px;
                    background-color: $managementTextUnderline;
                    margin: verticalSpace(lilac, mobile) 0;
                }
            }

            &__text {
                &__title {
                    font-size: 3.6rem;
                    line-height: calc(50 / 36);
                    @include fontTTFirstNeueDemiBold;
                    color: #10254e;
                }

                & > p {
                    color: #10254e;
                    &:nth-of-type(1) {
                        font-size: 1.2rem;
                        opacity: 0.5;
                        text-transform: uppercase;
                        line-height: calc(24 / 12);
                        margin-top: verticalSpace(mint, mobile);
                        @include fontMarkWebProMedium;
                    }
                }

                & > span {
                    display: block;
                    width: 65px;
                    height: 1px;
                    background-color: $managementTextUnderline;
                    margin: verticalSpace(lilac, mobile) 0;
                }

                &__description {
                    color: #10254e;

                    & p:nth-of-type(2) {
                        margin-top: verticalSpace(dijon, mobile);
                        font-size: 1.2rem;
                        line-height: calc(23 / 12);
                    }

                    & p:nth-of-type(3) {
                        padding-right: returnColumnOffset(1, mobile);
                    }
                }
            }
        }
    }

    &__background {
        height: 100%;
        position: absolute;
        top: 0;
        width: 100vw;
        z-index: -1;
        align-self: center;
        background-image: url('..//assets/images/management_mobile.png');

        @include mq($from: tablet) {
            background-image: none;
            background-color: $managementBackground;
            z-index: -2;
        }
    }
}
</style>
