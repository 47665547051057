<template>
    <section id="programme" class="programme">
        <div class="programme__container">
            <div class="programme__book">
                <img src="../assets/images/corner.png" />
                <div class="animation_container" ref="animationBook">
                    <div class="programme__book__images">
                        <img
                            ref="startTrigger"
                            src="../assets/images/cover.png"
                        />
                    </div>
                    <div class="programme__book__text" ref="text">
                        <IconLogoSmall />
                        <NumberPage label="03." />
                        <div>
                            <h2>{{ title }}</h2>
                            <span>{{ subtitle }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="programme__text">
                <div class="programme__text__container">
                    <span>{{ subtitle }}</span>
                    <span v-html="content"></span>
                </div>
                <div class="programme__text__background"></div>
            </div>
        </div>
    </section>
</template>

<script>
import { gsap } from 'gsap';
import ScrollMagic from 'scrollmagic';
import IconLogoSmall from '@/components/IconLogoSmall.vue';
import NumberPage from '@/components/NumberPage.vue';

import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap';
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators.js';

export default {
    name: 'TheProgramme',
    components: {
        IconLogoSmall,
        NumberPage,
    },
    props: ['data'],
    data() {
        return {
            isMobile: false,
            triggerPosition: Number,
            scene: null,
        };
    },
    methods: {
        checkDevice() {
            const windowWidth = window.innerWidth;
            if (windowWidth < 680) {
                this.isMobile = true;
            } else {
                this.isMobile = false;
            }
        },
        bookAnimation() {
            const controller = new ScrollMagic.Controller();
            let tween = gsap.fromTo(
                this.$refs.animationBook,
                3,
                { rotationY: '-20.5deg' },
                { rotationY: '0deg' }
            );
            tween.pause();
            const roundOff = (progress) => Math.round(progress * 100) / 100;
            const duration = window.innerHeight * 1.3;

            const startElements = document.querySelector(
                '.admissions__container__text__description'
            ).children;
            const startElementsLength = startElements.length - 1;
            let startElement = startElements[startElementsLength];
            const styles = window.getComputedStyle(startElement);

            if (!this.isMobile) {
                this.triggerPosition =
                    startElement.clientHeight +
                    parseFloat(styles['margin-top']);
            } else {
                this.triggerPosition = 0;
                startElement = document.querySelector('.animation_container');
            }

            if (this.scene) {
                this.scene.destroy();
            }

            this.scene = new ScrollMagic.Scene({
                triggerElement: startElement,
                offset: this.triggerPosition,
                triggerHook: 1,
                duration: `${duration}`,
            })
                .addTo(controller)
                .on('progress', function (event) {
                    if (duration > 0) {
                        tween.progress(roundOff(event.progress));
                    } else {
                        if (event.scrollDirection === 'FORWARD') {
                            tween.play();
                        } else {
                            tween.reverse();
                        }
                    }
                });
        },
    },
    computed: {
        title() {
            return this.data.title;
        },
        subtitle() {
            return this.data.extra_large;
        },
        content() {
            return this.data.description;
        },
    },
    created() {
        window.addEventListener('resize', () => {
            this.checkDevice();
            this.bookAnimation();
        });
    },
    mounted() {
        this.checkDevice();
        this.bookAnimation();
    },
    beforeDestroy() {
        window.removeEventListener('resize', () => {
            this.checkDevice();
            this.bookAnimation();
        });
    },
};
</script>

<style lang="scss" scoped>
.programme {
    position: relative;
    z-index: 10;
    background-image: url('../assets/images/programme_background.png');
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: verticalSpace(green, mobile) + 2.4rem;
    color: $programmeColorText;

    @include mq($from: tablet) {
        padding-top: 0;
    }

    &__container {
        @include container;
        display: flex;
        flex-direction: column;
    }

    &__book {
        display: flex;
        position: relative;
        width: fit-content;
        margin-top: verticalSpace(wood, mobile);
        z-index: 2;
        perspective: 1250px;

        @include mq($from: tablet) {
            margin-top: -175px;
            margin-left: -24px;
        }

        @include mq($from: desktop) {
            margin-top: -141px;
            margin-left: returnColumnOffset(2, desktop);
        }

        &__images {
            display: flex;
            width: 302px;

            @include mq($from: tablet) {
                width: 482px;
            }

            @include mq($from: desktop) {
                width: 798px;
            }

            & img:nth-of-type(1) {
                width: 100%;
            }
        }

        &__text {
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            transform-origin: 0% 0%;
            width: 100%;
            height: 100%;

            @include mq($from: tablet) {
                padding-left: 3.6vw;
            }

            @include mq($from: desktop) {
                padding-left: 6.3vw;
                padding-right: 7%;
            }

            & .number_page {
                position: absolute;
                top: calc(-24px - 40px);
                color: $white;

                @include mq($from: tablet) {
                    position: relative;
                    top: auto;
                    margin-left: 0;
                    margin-top: verticalSpace(green, tablet);
                    color: $black;
                }

                @include mq($from: desktop) {
                    margin-top: 190px;
                }

                p {
                    margin-top: 0;
                }
            }

            & svg {
                align-self: flex-end;
                margin: 22px 22px;
                width: 31px;
                height: 36px;

                @include mq($from: tablet) {
                    position: absolute;
                    top: 16px;
                    right: 16px;
                    width: 42px;
                    height: 48px;
                    margin: 0;
                }

                @include mq($from: desktop) {
                    top: 24px;
                    right: 43px;
                    width: 90px;
                    height: 90px;
                }
            }

            & h2 {
                @include title;
                align-self: center;
                margin-left: 44px;
                font-size: 3rem;
                line-height: calc(45 / 30);

                @include mq($from: tablet) {
                    align-self: auto;
                    margin-left: 0;
                    font-size: 5rem;
                    line-height: calc(45 / 50);
                }

                &::first-letter {
                    @include letter;
                    margin-right: -40px;

                    @include mq($from: tablet) {
                        margin-right: -64px;
                    }

                    @include mq($from: desktop) {
                        margin-right: -84px;
                        line-height: 0;
                    }
                }
            }

            & span {
                display: none;

                @include mq($from: desktop) {
                    display: block;
                    @include fontTTFirstNeueDemiBold;
                    font-size: 2.8rem;
                    line-height: calc(42 / 28);
                    padding: 70px 0 78px returnColumnOffset(6, desktop);
                }
            }
        }
    }

    &__text {
        margin-top: -231px;
        padding-top: 231px;
        padding-bottom: 60px;
        background-color: #ebebeb;
        width: 100vw;
        align-self: center;

        @include mq($from: tablet) {
            position: relative;
            width: calc(100vw - 3vw);
            align-self: flex-start;
            margin-left: -6vw;
            margin-top: 0px;
            padding-top: 0px;
            padding-bottom: 45px;
        }

        @include mq($from: desktop) {
            background-color: transparent;
            width: 100%;
            margin-left: 0;
            padding-bottom: 60px;
        }

        &__container {
            @include container;

            @include mq($from: tablet) {
                background-color: #ebebeb;
                width: calc(100vw - 79px);
                z-index: 1;
                margin: 0;
                margin-top: -170px;
                padding: 170px 116px 70px 6vw;
            }

            @include mq($from: desktop) {
                width: returnColumnOffset(14, desktop);
                margin: -430px 0 0 returnColumnOffset(8, desktop);
                padding: 500px returnColumnOffset(4, desktop) 70px
                    returnColumnOffset(2, desktop);
                min-width: auto;
                max-width: auto;
            }

            & > span {
                &:nth-of-type(1) {
                    @include fontTTFirstNeueDemiBold;
                    display: block;
                    margin-top: verticalSpace(wood, mobile);
                    padding-right: 38px;
                    font-size: 2rem;
                    line-height: calc(35 / 20);

                    @include mq($from: tablet) {
                        font-size: 2.8rem;
                        line-height: calc(42 / 28);
                        margin: verticalSpace(wood, tablet) 0
                            verticalSpace(wood, tablet) 5.2%;
                    }

                    @include mq($from: desktop) {
                        display: none;
                    }
                }

                &:nth-of-type(2) {
                    display: block;
                    padding-right: 55px;

                    @include mq($from: tablet) {
                        margin-left: 5.2%;
                    }

                    @include mq($from: desktop) {
                        margin-left: 0;
                        padding-right: 0;
                    }
                    p {
                        font-size: 1.4rem;
                        @include fontMarkWebProBook;
                    }
                }
            }
        }

        &__background {
            display: none;

            @include mq($from: tablet) {
                display: block;
                width: 100%;
                height: calc(100% + 64px);
                position: absolute;
                top: 0;
                background-image: url('../assets/images/programme_background_text.png');
            }

            @include mq($from: desktop) {
                height: auto;
                width: auto;
                top: -490px;
                left: returnColumnOffset(1, desktop);
                right: returnColumnOffset(1, desktop);
                bottom: -162px;
            }
        }
    }

    & img {
        height: 418px;
        box-shadow: 40px 40px 40px 0px rgba(0, 0, 0, 0.2);

        @include mq($from: tablet) {
            height: 643px;
        }

        @include mq($from: desktop) {
            height: 1064px;
            margin-left: 0;
        }
    }

    .animation_container {
        transform-origin: 0% 0%;
    }
}
</style>
