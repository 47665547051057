<template>
    <section id="admissions" class="admissions">
        <div class="admissions__container">
            <NumberPage label="02." />
            <div class="admissions__container__image"></div>
            <div class="admissions__container__text">
                <h2 class="admissions__container__text__title">{{ title }}</h2>
                <span class="admissions__container__text__subtitle">{{
                    subtitle
                }}</span>
                <div
                    class="admissions__container__text__description"
                    v-html="content"
                ></div>
            </div>
        </div>
    </section>
</template>

<script>
import NumberPage from '@/components/NumberPage.vue';

export default {
    name: 'TheAdmissions',
    props: ['data'],
    components: {
        NumberPage,
    },
    computed: {
        title() {
            return this.data.title;
        },
        subtitle() {
            return this.data.extra_large;
        },
        content() {
            return this.data.description;
        },
    },
};
</script>

<style lang="scss" scoped>
.admissions {
    background-image: url('../assets/images/background_admissions.png');

    &__container {
        display: flex;
        flex-direction: column;
        width: 100%;
        @include container;
        padding-top: verticalSpace(green, mobile);

        @include mq($from: desktop) {
            flex-direction: row;
            flex-wrap: wrap;
            padding-top: verticalSpace(sand, desktop);
        }

        & .number_page {
            color: $white;

            @include mq($from: desktop) {
                flex: 1 0 auto;
                margin-right: 100%;
                align-self: flex-start;
            }
        }

        &__image {
            width: 302px;
            height: 234px;
            align-self: center;
            margin-top: verticalSpace(lilac, mobile);
            background-image: url('../assets/images/drops.png');
            background-repeat: no-repeat;

            @include mq($from: tablet) {
                width: 404px;
                height: 387px;
                background-size: contain;
                background-image: url('../assets/images/drops_larger.png');
            }

            @include mq($from: desktop) {
                width: 536px;
                max-width: 50%;
                height: 515px;
                flex: 0 0 auto;
                align-self: flex-start;
                margin: verticalSpace(gray, desktop) -100px 0 returnColumnOffset(3, desktop);
            }
        }

        &__text {
            color: $white;
            padding-bottom: 60px;
            margin-top: -105px;

            @include mq($from: tablet) {
                padding: 0 returnColumnOffset(1, tablet);
                margin-top: -160px;
            }

            @include mq($from: desktop) {
                width: 47%;
                margin-top: 44px;
                padding: 0;
            }

            &__title {
                @include title;
                color: $white;
                font-size: 3rem;
                line-height: calc(45 / 30);
                margin-left: 7px;

                @include mq($from: tablet) {
                    font-size: 5rem;
                    line-height: calc(45 / 50);
                    margin-left: 10px;
                }

                @include mq($from: desktop) {
                    margin-left: 15px;
                }

                &::first-letter {
                    @include letter;
                    font-weight: normal;
                    margin-right: 6px;

                    @include mq($from: desktop) {
                        line-height: auto;
                    }
                }
            }

            &__subtitle {
                display: block;
                @include fontTTFirstNeueDemiBold;
                margin-top: -16px;
                display: block;
                padding-right: returnColumnOffset(1, mobile);
                font-size: 2rem;
                line-height: calc(35 / 20);

                @include mq($from: tablet) {
                    margin-top: -36px;
                    font-size: 2.8rem;
                    line-height: calc(42 / 28);
                    padding-right: returnColumnOffset(1, tablet);
                }

                @include mq($from: desktop) {
                    margin-top: -60px;
                    padding-right: returnColumnOffset(4, desktop);
                }
            }

            &__description {
                margin-top: verticalSpace(wood, mobile);
                padding-right: returnColumnOffset(1, mobile);

                & p:nth-of-type(1) {
                    margin-top: 0;
                }

                @include mq($from: tablet) {
                    margin-bottom: 294px;
                    padding-right: returnColumnOffset(1, tablet);
                }

                @include mq($from: desktop) {
                    padding-right: returnColumnOffset(4, desktop);
                }
            }
        }
    }
}
</style>
