<template>
  <div class="video" ref="videoContainer" :class="{is_clicked: isClicked}">
    <video autoplay muted loop playsinline ref="video" v-on:click="fullScreen" :class="{is_clicked: isClicked}">
      <source :src="url" type="video/mp4" >
    </video>
    <div class="hexagon" ref="button" v-on:click="fullScreen" v-show="!isClicked">
      <PlayButton />
    </div>
    <div class="x" ref="close" v-on:click="closeFullScreen" :class="{is_clicked: isClicked}">
      <span></span>
      <span></span>
    </div>
  </div>
</template>

<script>
import PlayButton from '@/components/PlayButton.vue';
import { gsap } from 'gsap';
import ScrollMagic from 'scrollmagic';

export default {
  name: 'VideoExtension',
  props: ['url'],
  data() {
    return {
      isPlaying: null,
      isScrolled: true,
      isClicked: false,
      msScene: null,
      msController: new ScrollMagic.Controller()
    }
  },
  components: {
    PlayButton
  },
  methods: {
    behaviorVideo() {
      let options = {
            root: null,
            rootMargin: '0px',
            threshold: 0
      }
      let callback = (entries) => {
        let i = entries.length - 1;
        if(entries[i].isIntersecting) {
          this.$refs.video.play();
        } else {
          this.$refs.video.pause();
        }
      }
      let observer = new IntersectionObserver(callback, options);
      observer.observe(this.$refs.video);
    },
    fullScreen() {
      this.isClicked = true;
      document.body.style.overflow = 'hidden';
    },
    closeFullScreen() {
      this.isClicked = false;
      document.body.style.overflow = 'scroll';
    },
    changeVideoWidth(){
      const duration = document.querySelector('.section_with_video').offsetHeight;
      let tween = gsap.to(this.$refs.videoContainer, 3, {scale: 0.7, ease: "none"})
      tween.pause()
      const roundOff = progress => Math.round(progress * 100) / 100

      if(this.msScene) {
				this.msScene.destroy(true)
			}
      
      this.msScene = new ScrollMagic.Scene({
        triggerHook: 0,
        duration: `${duration - 230}`
        })
        .addTo(this.msController)
        .setPin(this.$refs.videoContainer)
        .on('progress', function(event) {
            if (duration > 0) {
            tween.progress(roundOff(event.progress))
          } else {
            if (event.scrollDirection === 'FORWARD') {
              tween.play()
            } else {
              tween.reverse()
            }
          }
        })
    }
  },	
  created() {
		window.addEventListener('resize', () => {
      if(window.innerWidth >= 980){
        this.changeVideoWidth()
      } else if(this.msScene) {
          this.msScene.destroy(true)
      }
		})
	},
  mounted() {
    this.behaviorVideo()
    if(window.innerWidth >= 980){
      this.changeVideoWidth()
    } 
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.changeVideoWidth);
  }
}
</script>

<style lang="scss" scoped>
.video {
    position: absolute;
    width: 50%;
    z-index: 5;
    margin-top: -25%;
    top: 0;
    right: returnColumnOffset(1, mobile);
    overflow: hidden;
    border-radius: 50%;
    box-shadow: 10px 6px 0px 0px black;
    transition: .2s border-radius, .2s width, .2s max-height, .2s transform;
    cursor: pointer;

    &:after{ 
      content: '';
      display: block;
      width: 100%;
      padding-bottom: 100%;
    }

    @include mq($from: tablet) {
      margin-top: 0;
      top: -196px;
      right: 0px;
    }

    @include mq($from: desktop) {
      transform-origin: center right;
      width: 464px;
      top: -241px;
      right: returnColumnOffset(3, desktop);
    }

    &.is_clicked {
      position: fixed !important;
      top: 0 !important;
      right: 0 !important;
      left: 0 !important;
      transform: scale(1) !important;

      width: 100vw;
      height: calc(100vh - 56px);
      background-color: $black;
      max-width: none;
      border-radius: 0;
      z-index: 15;
      margin-top: 0;
      box-shadow: none;

      transition: .2s border-radius, .2s width, .2s max-height, .2s transform;

      @include mq($from: tablet) {
        height: 100vh;
      }
    }
  & > video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;

    &.is_clicked {
      animation-name: animation;
      animation-name: animation;
      animation-duration: .4s;
      animation-delay: .4s;
      animation-fill-mode: both;

       @include mq($and: (orientation: landscape)) {
        object-fit: contain;
      }
       @include mq($to: tablet) {
        object-fit: contain;
      }
    }
  }

  & > .hexagon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 52px;
    height: 60px;
    cursor: pointer;
  }

  & .x {
    position: absolute;
    top: 30px;
    right: 30px;
    width: 30px;
    height: 30px;
    cursor: pointer;
    opacity: 0;
    visibility: hidden;
    transition: .4s opacity;
    transition-delay: .4s;

    & span {
      position: absolute;
      top: 50%;
      left: 50%;
      background-color: $white;
      height: 2px;
      width: 100%;

      &:nth-child(1) {
        transform: translate(-50%, -50%) rotate(45deg);
      }

      &:nth-child(2) {
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }

    &.is_clicked {
      opacity: 1;
      visibility: visible;
    }
  }

  @keyframes animation {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
    
  }
}
</style>