<template>
    <div id="app">
        <TheHeader v-if="menu" :data="menu" />
        <SectionWithVideo v-if="about" :data="about" />
        <TheManagement v-if="management" :data="management" />
        <TheAdmissions v-if="admissions" :data="admissions" />
        <TheProgramme v-if="programme" :data="programme" />
        <TeachingStaff v-if="teaching_staff" :data="teaching_staff" />
        <AkademeiaConsulting
            v-if="akademeia_consulting"
            :data="akademeia_consulting"
        />
        <TheContact v-if="contact" :data="contact" />
    </div>
</template>

<script>
import AkademeiaConsulting from '@/components/AkademeiaConsulting.vue';
import SectionWithVideo from '@/components/SectionWithVideo.vue';
import TeachingStaff from '@/components/TeachingStaff.vue';
import TheAdmissions from '@/components/TheAdmissions.vue';
import TheContact from '@/components/TheContact.vue';
import TheHeader from '@/components/TheHeader.vue';
import TheManagement from '@/components/TheManagement.vue';
import TheProgramme from '@/components/TheProgramme.vue';

import { ServiceFactory } from '@/api/ServiceFactory';

const homePageService = ServiceFactory.get('homePageService');

export default {
    name: 'app',
    data() {
        return {
            about: false,
            management: false,
            admissions: false,
            programme: false,
            teaching_staff: false,
            akademeia_consulting: false,
            contact: false,
            menu: false,
        };
    },
    components: {
        AkademeiaConsulting,
        SectionWithVideo,
        TeachingStaff,
        TheAdmissions,
        TheContact,
        TheHeader,
        TheManagement,
        TheProgramme,
    },
    methods: {
        async getData() {
            try {
                const res = await homePageService.get();
                const {
                    about,
                    management,
                    admissions,
                    programme,
                    teaching_staff,
                    akademeia_consulting,
                    contact,
                    menu,
                } = res.data;

                this.about = about || false;
                this.management = management || false;
                this.admissions = admissions || false;
                this.programme = programme || false;
                this.teaching_staff = teaching_staff || false;
                this.akademeia_consulting = akademeia_consulting || false;
                this.contact = contact || false;
                this.menu = menu || false;
            } catch (err) {
                console.log(err);
            }
        },
    },
    created() {
        this.getData();
    },
};
</script>

<style>
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

html {
    font-size: 10px;
}

body {
    background-color: #0f2652;
}
</style>
