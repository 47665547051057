<template>
    <header id="aboutus" class="header">
        <!-- <img :src="logoSrc" :alt="logoAlt" /> -->
        <IconLogo />
        <div class="header__menu">
            <input type="checkbox" ref="menu_button" v-model="checkbox" />
            <span class="hamburger"></span>
            <span class="hamburger"></span>
            <span class="hamburger"></span>
            <p class="header__menu__title">Menu</p>
            <nav class="header__menu__nav">
                <ul class="header__menu__nav__items">
                    <div class="wrapper">
                        <li
                            v-for="(item, n) in menu"
                            :key="item.id"
                            class="header__menu__nav__items__item"
                        >
                            <span>0{{ n + 1 }}.</span>
                            <span></span>
                            <a
                                :href="`#${title(n)}`"
                                class="header__menu__nav__items__item__link"
                                @click="menuOff"
                                >{{ item.title }}</a
                            >
                        </li>
                    </div>
                </ul>
            </nav>
        </div>
    </header>
</template>

<script>
// import axios from 'axios';
import { ServiceFactory } from '@/api/ServiceFactory';
import IconLogo from '@/components/IconLogo.vue';

const OptionService = ServiceFactory.get('optionService');

export default {
    name: 'TheHeader',
    props: ['data'],
    components: {
        IconLogo,
    },
    data() {
        return {
            logoAlt: '',
            logoSrc: '',
            checkbox: false,
        };
    },
    computed: {
        menu() {
            return this.data;
        },
    },
    methods: {
        async getData() {
            try {
                const res = await OptionService.get('logo');
                const image = res.data;

                this.logoSrc = image.url;
                this.logoAlt = image.alt;
            } catch (err) {
                console.log(err);
            }
        },
        menuOff() {
            this.checkbox = false;
        },
        title(n) {
            return this.data[n].title.toLowerCase().trim().replace(' ', '');
        },
    },
    mounted() {
        // this.getData();
    },
};
</script>

<style lang="scss" scoped>
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding-top: verticalSpace(lilac, mobile);
    padding-bottom: 144px; //vertical color + height number_page
    background: #10254e;
    width: 100%;
    max-width: 100vw;
    padding-left: 4vw;
    padding-right: 4vw;

    @include mq($from: tablet) {
        padding-top: verticalSpace(green, tablet);
        align-items: flex-start;
        padding-bottom: 64px; //vertical color number_page + height number_page

        &::v-deep svg {
            margin-bottom: verticalSpace(green, tablet);
        }
    }

    @include mq($from: desktop) {
        padding-top: verticalSpace(blue, desktop);

        &::v-deep svg {
            margin-left: returnColumnOffset(4, desktop);
            margin-bottom: verticalSpace(green, desktop);
        }
    }

    &__menu {
        position: fixed;
        right: returnColumnOffset(1, mobile);
        z-index: 15;

        @include mq($from: desktop) {
            margin-top: 10px;
        }

        & input {
            position: fixed;
            z-index: 3;
            cursor: pointer;
            height: 45px;
            width: 33px;
            opacity: 0;

            &:checked {
                & ~ .header__menu__nav {
                    opacity: 1;
                    visibility: visible;
                    transform: translateX(0);
                    transition: opacity 0.3s, visibility 0.3s, transform 0.3s;

                    &:after {
                        transition: opacity 0.3s, visibility 0.3s,
                            transform 0.3s;
                        opacity: 1;
                        transform: scale(1);
                    }
                }

                & ~ .hamburger {
                    position: relative;
                    z-index: 2;
                }

                & ~ .header__menu__title {
                    visibility: hidden;
                }

                & ~ .hamburger:nth-of-type(1) {
                    transform: rotate(-45deg);
                    transform-origin: 29px 5px;
                    transition-delay: 0.2s;
                }

                & ~ .hamburger:nth-of-type(2) {
                    transform: translateX(-50%);
                    opacity: 0;
                    transition-delay: 0s;
                }

                & ~ .hamburger:nth-of-type(3) {
                    transform: rotate(45deg);
                    transform-origin: 19px 2px;
                    transition-delay: 0.2s;
                }

                & ~ .header__menu__nav .header__menu__nav__items__item {
                    &__link {
                        transform: translateX(0px);
                        opacity: 1;
                        transition: opacity 0.4s, transform 0.4s;
                        transition-delay: 0.3s;
                    }

                    & span:nth-of-type(1) {
                        transform: translateX(0px);
                        opacity: 1;
                        transition: opacity 0.4s, transform 0.4s;
                        transition-delay: 0.3s;
                    }

                    & span:nth-of-type(2) {
                        transform: translateX(0px);
                        opacity: 1;
                        transition: opacity 0.4s, transform 0.4s;
                        transition-delay: 0.3s;
                    }

                    $seconds: 0.12;
                    @for $i from 1 through 6 {
                        &:nth-child(#{$i})
                            .header__menu__nav__items__item__link {
                            transition-delay: #{$seconds * $i}s;
                        }
                    }

                    $seconds2: 0.14;
                    @for $i from 1 through 6 {
                        &:nth-child(#{$i}) span:nth-of-type(1) {
                            transition-delay: #{$seconds2 * $i}s;
                        }
                    }

                    @for $i from 1 through 6 {
                        &:nth-child(#{$i}) span:nth-of-type(2) {
                            transition-delay: #{$seconds * $i}s;
                        }
                    }
                }
            }
        }

        & .hamburger {
            display: block;
            width: 29px;
            height: 2px;
            border-radius: 2px;
            background-color: $menuSpanColor;
            margin-bottom: 5px;
            transition: transform 0.3s;

            &:nth-of-type(1) {
                transform-origin: 29px 5px;
            }

            &:nth-of-type(2) {
                opacity: 1;
                transition: transform 0.3s, opacity 0.3s;
                transition-delay: 0.3s;
            }

            &:nth-of-type(3) {
                transform-origin: 19px 2px;
            }
        }

        &__title {
            font-size: 1.2rem;
            line-height: 1;
            color: $white;
            margin-top: 10px;

            @include fontMarkWebProMedium;
        }

        &__nav {
            position: fixed;
            top: 0;
            left: 0;
            width: 100vw;
            height: 100vh;

            transform: translateX(0%);
            opacity: 1;
            visibility: hidden;
            transition: opacity 0.3s, visibility 0.3s, transform 0.3s;

            &:after {
                content: '';
                position: absolute;
                top: 0px;
                left: 0px;
                right: 0px;
                bottom: 0px;
                background-image: url('../assets/images/marble-blue.png');
                z-index: -1;
                opacity: 0;
                transform: scale(1.1);
                transition: opacity 0.3s, visibility 0.3s, transform 0.4s,
                    background-image 0.3s;
            }

            &__items {
                display: flex;
                flex-direction: column;
                height: 100%;
                justify-content: center;
                align-items: center;

                & .wrapper {
                    overflow: hidden;
                }

                &__item {
                    display: flex;
                    align-items: center;
                    position: relative;
                    cursor: pointer;
                    list-style-type: none;
                    margin-bottom: 40px;
                    padding-bottom: 3px;
                    font-family: 'TTFirstNeueDemiBold';

                    overflow: hidden;

                    & > span:nth-of-type(1) {
                        position: relative;
                        z-index: 2;
                        line-height: 1;
                        font-size: 0.8rem;
                        padding-bottom: 5px;
                        margin-right: 30px;
                        color: $white;

                        opacity: 0;
                        transform: translateY(20px);
                        transition: transform 0.4s;
                        transition-delay: 0.3s;

                        @include mq($from: tablet) {
                            font-size: 1.2rem;
                            margin-right: 50px;
                            padding-bottom: 0px;
                        }

                        @include mq($from: desktop) {
                            line-height: calc(24 / 12);
                        }
                    }

                    & > span:nth-of-type(2) {
                        display: block;
                        position: absolute;
                        z-index: 1;
                        left: 0;
                        bottom: 9px;
                        width: 25%;
                        height: 1px;
                        background-color: $menuSpanColor;

                        opacity: 0;
                        transform: translateX(-100%);
                        transition: transform 0.4s;
                        transition-delay: 0.3s;

                        @include mq($from: tablet) {
                            width: 20%;
                            bottom: 15px;
                        }

                        @include mq($from: desktop) {
                            width: 14%;
                            bottom: 16px;
                        }
                    }

                    &__link {
                        display: block;
                        font-size: 1.6rem;
                        color: $white;
                        text-decoration: none;

                        opacity: 0;
                        transform: translateX(-40px);
                        transition: transform 0.4s;
                        transition-delay: 0.3s;

                        &:hover {
                            color: $menuSpanColor;
                        }

                        @include mq($from: tablet) {
                            font-size: 3rem;
                        }

                        @include mq($from: desktop) {
                            font-size: 5rem;
                            line-height: 1;
                        }
                    }
                }
            }
        }
    }

    // &__number_page {
    //   @include numberPage;
    //   color: $white;
    //   margin: verticalSpace(green, mobile) calc(100% - 115px) verticalSpace(green, mobile) 0;

    //   @include mq($from: tablet) {
    //     margin: verticalSpace(pink, tablet) calc(95.8% - 115px) verticalSpace(pink, tablet) returnColumnOffset(1, tablet);
    //   }

    //   @include mq($from: desktop) {
    //      margin: verticalSpace(pink, desktop) calc(83.3% - 115px) verticalSpace(pink, desktop) returnColumnOffset(4, desktop);
    //   }

    //   & > p {
    //     margin-top: 0;
    //   }
    // }
}
</style>
