<template>
    <section id="consulting" class="consulting">
        <div class="consulting__container">
            <NumberPage label="05." />
            <span class="consulting__container__letter">{{ title[0] }}</span>
            <h2 class="consulting__container__title">{{ title }}</h2>
            <div
                class="consulting__container__description"
                v-html="content"
            ></div>
        </div>
        <div class="consulting__background"></div>
    </section>
</template>

<script>
import NumberPage from '@/components/NumberPage.vue';

export default {
    name: 'AkademeiaConsulting',
    props: ['data'],
    components: {
        NumberPage,
    },
    data() {
        return {};
    },
    computed: {
        title() {
            return this.data.title;
        },
        content() {
            return this.data.description;
        },
    },
};
</script>

<style lang="scss" scoped>
.consulting {
    display: flex;
    flex-direction: column;
    @include container;

    &__container {
        padding-top: verticalSpace(green, mobile);
        text-align: center;

        @include mq($from: tablet) {
            padding-top: verticalSpace(green, tablet);
        }

        @include mq($from: desktop) {
            padding-top: 16%;
        }

        & .number_page {
            text-align: left;
            color: $black;
        }

        &__letter {
            display: block;
            padding-top: verticalSpace(wood, mobile);
            @include letter;
            color: #10254e;

            @include mq($from: tablet) {
                padding-top: verticalSpace(wood, tablet);
            }

            @include mq($from: desktop) {
                padding-top: verticalSpace(gold, desktop);
            }
        }

        &__title {
            @include title;
            color: #10254e;
        }

        &__description {
            margin-top: verticalSpace(wood, mobile);
            margin-bottom: 60px;
            padding-left: returnColumnOffset(1, mobile);
            padding-right: returnColumnOffset(1, mobile);

            @include mq($from: tablet) {
                margin-top: verticalSpace(wood, tablet);
                margin-bottom: 70px;
                padding-left: returnColumnOffset(2, tablet);
                padding-right: returnColumnOffset(2, tablet);
            }

            @include mq($from: desktop) {
                margin-top: verticalSpace(wood, desktop);
                margin-bottom: 20%;
                padding-left: returnColumnOffset(8, desktop);
                padding-right: returnColumnOffset(8, desktop);
            }

            & > p {
                @include description;
                color: #10254e;
            }
        }
    }

    &__background {
        position: absolute;
        top: 0;
        height: 100%;
        width: 100vw;
        align-self: center;
        background-image: url('../assets/consulting-background.png');
        z-index: -1;

        @include mq($from: desktop) {
            width: returnColumnOffset(20, desktop);
        }
    }
}
</style>
