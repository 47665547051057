import OptionService from './OptionService';
import HomePageService from './HomePageService';

const services = {
    optionService: OptionService,
    homePageService: HomePageService
}

export const ServiceFactory = {
    get: name => services[name]
}