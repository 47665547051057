<template>
    <section class="section_with_video">
        <div class="inner">
            <VideoExtension :url="videoUrl" />
            <NumberPage label="01." />
            <div class="inner__offset">
                <span class="title">{{ title }}</span>
                <div class="subtitle">{{ subtitle }}</div>
                <div class="content" v-html="content"></div>
            </div>
            <span class="bg1"></span>
            <span class="bg2"></span>
            <span class="bg3"></span>
        </div>
    </section>
</template>

<script>
import VideoExtension from '@/components/VideoExtension.vue';
import NumberPage from '@/components/NumberPage.vue';

export default {
    name: 'SectionWithVideo',
    props: ['data'],
    components: {
        VideoExtension,
        NumberPage,
    },
    computed: {
        title() {
            return this.data.title;
        },
        subtitle() {
            return this.data.extra_large;
        },
        content() {
            return this.data.description;
        },
        videoUrl() {
            return this.data.video.url;
        },
    },
};
</script>

<style lang="scss" scoped>
.section_with_video {
    color: #fff;
    background-color: #10254e;

    .inner {
        @include container();
        position: relative;

        &__offset {
            @include mq($from: tablet) {
                padding-left: returnColumnOffset(1, tablet);
                padding-right: returnColumnOffset(1, tablet);
            }

            @include mq($from: desktop) {
                padding-left: returnColumnOffset(4, desktop);
            }
        }

        & .number_page {
            z-index: 3;
            position: absolute;
            top: -108px; //margin + height + vertical color

            @include mq($from: tablet) {
                top: -92px; //margin + height + vertical color
            }

            @include mq($from: desktop) {
                top: -94px; //margin + height + vertical color
            }
        }

        .bg1 {
            position: absolute;
            bottom: 0;
            left: -8vw;
            width: 100vw;
            height: 100%;
            background-image: url('../assets/images/video_section_bg.png');
            background-size: 106px auto;
            z-index: 2;
            opacity: 0.6;

            @include mq($from: tablet) {
                width: 100%;
                left: 0;
                height: calc(100% - 70px);
            }

            @include mq($from: desktop) {
                width: returnColumnOffset(20, desktop);
                left: returnColumnOffset(2, desktop);
                height: calc(100% - 170px);
            }
        }

        .bg3 {
            position: absolute;
            left: -8vw;
            width: 100vw;
            background: $managementBackground;
            opacity: 0.6;
            z-index: 3;
            top: 0;
            height: 100%;

            @include mq($from: tablet) {
                width: 100%;
                left: 0;
                top: 70px;
                height: calc(100% - 60px);
            }

            @include mq($from: desktop) {
                top: 162px;
                width: returnColumnOffset(20, desktop);
                left: returnColumnOffset(2, desktop);
            }
        }

        .bg2 {
            position: absolute;
            bottom: -130px;
            left: -8vw;
            width: 100vw;
            height: 100%;
            background: $managementBackground;
            z-index: 1;

            @include mq($from: tablet) {
                width: 106.9%;
                left: -3.45%;
                height: calc(100% - 70px);
            }

            @include mq($from: desktop) {
                width: returnColumnOffset(22, desktop);
                left: returnColumnOffset(1, desktop);
                height: calc(100% - 170px);
            }
        }
    }

    .title {
        display: inline-block;
        font-size: 4rem;
        line-height: 1;
        @include fontTTFirstNeueDemiBold;
        color: #fff;
        padding: 0;
        position: relative;
        z-index: 5;

        &::first-letter {
            line-height: 1;
            font-size: 20rem;
        }

        @include mq($from: tablet) {
            font-size: 5rem;

            &::first-letter {
                font-size: 30rem;
            }
        }

        @include mq($from: desktop) {
            line-height: calc(45 / 50);

            &::first-letter {
                font-size: 40rem;
            }
        }

        // &::first-letter {
        //   line-height: 1;
        //   font-size: 20rem;
        // 	text-transform: uppercase;
        //   // padding: 0 10px 10px 0;

        // 	@include mq($from: tablet) {
        // 		font-size: 30rem;
        // 	}

        // 	@include mq($from: desktop) {
        // 		font-size: 40rem;
        // 	}
        // }
    }

    .subtitle {
        position: relative;
        z-index: 5;
        @include fontTTFirstNeueDemiBold;
        font-size: 20px;
        line-height: 1.75;

        @include mq($from: tablet) {
            font-size: 3rem;
            line-height: 1.5;
        }

        @include mq($from: desktop) {
            max-width: returnColumnOffset(10, desktop);
        }
    }

    .content {
        position: relative;
        z-index: 5;
        padding-bottom: verticalSpace(green, mobile);

        @include mq($from: tablet) {
            padding-bottom: verticalSpace(green, tablet);
        }

        @include mq($from: desktop) {
            max-width: returnColumnOffset(10, desktop);
            padding-bottom: verticalSpace(green, desktop);
        }
    }
}
</style>
